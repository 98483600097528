import React from 'react';

import { Canvas } from 'react-three-fiber';
import Box from './three/box';

import { Html } from 'drei';

import Layout from './layout';
import threeStyles from './three.module.scss';

const Three = ({ children }) => {
	return (
		<Canvas className={threeStyles.canvas} shadowMap>
			<Html className={threeStyles.content} prepend={true} fullscreen={true}>
				<Layout>{children}</Layout>
			</Html>
			<ambientLight intensity={0.8} />
			<pointLight
				intensity={0.8}
				position={[
					10,
					10,
					10
				]}
			/>

			<Box />
		</Canvas>
	);
};

export default Three;
