import React, { useRef } from 'react';
import { useFrame } from 'react-three-fiber';

import { MeshWobbleMaterial } from 'drei';

const Box = (p) => {
	const mesh = useRef();

	// Rotate mesh every frame, this is outside of React without overhead
	useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.002));

	return (
		<mesh
			{...p}
			ref={mesh}
			scale={[
				13.5,
				13.5,
				13.5
			]}
			castShadow
		>
			<icosahedronBufferGeometry
				attach="geometry"
				args={[
					1,
					3
				]}
			/>
			<MeshWobbleMaterial
				wireframe={true}
				attach="material"
				color="white"
				factor={3} // Strength, 0 disables the effect (default=1)
				speed={0.3}
			/>
		</mesh>
	);
};

export default Box;
