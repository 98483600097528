import React from 'react';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
import socialStyles from './social.module.scss';
import { Link } from 'gatsby';
const Social = () => {
	const NavLink = (props) => (
		<Link
			{...props}
			getProps={({ isCurrent }) => {
				// the object returned here is passed to the
				// anchor element's props
				return {
					style: {
						background: isCurrent ? '#fff' : '#eee',
						fontSize: isCurrent ? '1.2em' : '1em'
					}
				};
			}}
		/>
	);
	return (
		<div>
			<div className={socialStyles.socialbar}>
				<a href="https://github.com/james5968" target="blank">
					<FontAwesome name="github" />
				</a>
				<a href="https://www.linkedin.com/in/james-ashford-648551b0/" target="blank">
					<FontAwesome name="linkedin" />
				</a>
				<a href="mailto:james.ashford@hotmail.com">
					<FontAwesome name="envelope" />
				</a>
				<a href="https://www.facebook.com/james.ashford2" target="blank">
					<FontAwesome name="facebook" />
				</a>
			</div>
			<div className={socialStyles.mobileSocial}>
				<NavLink to="/contact">
					<FontAwesome name="comment" />
				</NavLink>
			</div>
		</div>
	);
};

export default Social;
