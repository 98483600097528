import React from 'react';
import Social from './Social';
import '../styles/base.scss';
import layoutStyles from './layout.module.scss';
import Navbar from './nav/navbar';

const Layout = ({ children }) => {
	return (
		<div className={layoutStyles.page}>
			<div className={layoutStyles.toolbar}>
				<div>
					<svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M195 100C195 152.467 152.467 195 100 195C47.5329 195 5 152.467 5 100C5 47.5329 47.5329 5 100 5C152.467 5 195 47.5329 195 100Z"
							stroke="#666"
							strokeWidth="6"
						/>
						<path
							d="M42.84 134.9C39 134.9 35.49 133.97 32.31 132.11C29.19 130.19 26.7 127.67 24.84 124.55C22.98 121.37 22.05 117.92 22.05 114.2C22.05 113.36 22.29 112.7 22.77 112.22C23.31 111.74 23.97 111.5 24.75 111.5C25.53 111.5 26.16 111.74 26.64 112.22C27.18 112.7 27.45 113.36 27.45 114.2C27.45 117.02 28.14 119.6 29.52 121.94C30.96 124.22 32.85 126.05 35.19 127.43C37.53 128.81 40.08 129.5 42.84 129.5C45.66 129.5 48.21 128.81 50.49 127.43C52.83 125.99 54.69 124.13 56.07 121.85C57.45 119.51 58.14 116.96 58.14 114.2V66.41C58.14 65.63 58.41 65 58.95 64.52C59.49 63.98 60.12 63.71 60.84 63.71C61.62 63.71 62.25 63.98 62.73 64.52C63.27 65 63.54 65.63 63.54 66.41V114.2C63.54 117.98 62.61 121.43 60.75 124.55C58.89 127.67 56.37 130.19 53.19 132.11C50.07 133.97 46.62 134.9 42.84 134.9Z"
							fill="#666"
						/>
						<path
							d="M78.8801 141.29C77.9201 141.29 77.1401 140.9 76.5401 140.12C76.0001 139.34 75.9401 138.44 76.3601 137.42L105.43 65.06C105.85 63.92 106.69 63.35 107.95 63.35C109.15 63.35 109.93 63.8 110.29 64.7C110.65 65.6 110.68 66.47 110.38 67.31L81.4001 139.76C81.1601 140.36 80.8001 140.75 80.3201 140.93C79.8401 141.17 79.3601 141.29 78.8801 141.29Z"
							fill="#666"
						/>
						<path
							d="M119.178 134C118.038 134 117.228 133.55 116.748 132.65C116.328 131.75 116.328 130.91 116.748 130.13L144.018 65.6C144.498 64.34 145.368 63.71 146.628 63.71C147.828 63.71 148.698 64.34 149.238 65.6L176.598 130.13C177.078 130.97 177.048 131.84 176.508 132.74C175.968 133.58 175.188 134 174.168 134C172.968 134 172.128 133.43 171.648 132.29L164.988 116.36H128.358L121.698 132.29C121.278 133.43 120.438 134 119.178 134ZM130.428 111.5H162.918L146.628 72.62L130.428 111.5Z"
							fill="#666"
						/>
					</svg>

					<div className={layoutStyles.vert}>
						<Navbar />
					</div>
				</div>

				<Social />
			</div>

			<div className={layoutStyles.contentContainer}>{children}</div>
		</div>
	);
};

export default Layout;
