import React from 'react';
import { Link } from 'gatsby';
import aboutStyles from './navbar.module.scss';

const Navbar = () => {
	const NavLink = (props) => (
		<Link
			{...props}
			getProps={({ isCurrent }) => {
				// the object returned here is passed to the
				// anchor element's props
				return {
					style: {
						background: isCurrent ? '#fff' : '#eee',
						fontSize: isCurrent ? '1.2em' : '1em'
					}
				};
			}}
		/>
	);
	return (
		<div className={aboutStyles.header}>
			<div className={aboutStyles.links}>
				<NavLink to="/">HOME</NavLink>

				<NavLink to="/projects">PROJECTS</NavLink>
			</div>
		</div>
	);
};

export default Navbar;
