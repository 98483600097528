import React from 'react';
import Three from '../components/Three';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';

import indexStyle from './index.module.scss';

const Layout = ({ location, children }) => {
	return (
		<Three>
			<TransitionProvider location={location}>
				<TransitionViews>{children}</TransitionViews>
			</TransitionProvider>
		</Three>
	);
};

export default Layout;
