// import React from 'react';
// import Layout from './src/components/Three';
// import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';

// export const wrapPageElement = ({ element, ...restProps }, ...args) => {
// 	return (
// 		// <Layout name="wrapPageElement" props={{}} args={args} mode="browser">
// 		<Layout name="wrapPageElement" props={restProps} args={args} mode="browser">
// 			{element}
// 		</Layout>
// 	);
// };
exports.shouldUpdateScroll = () => {
	return false;
};
